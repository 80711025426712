.screen-board{
  min-height: 220px;
  margin-bottom: 10px;
  width: 100%;
  border-bottom: 2px solid #C1C1C1;
  position: relative;
}

.screen-try{
  text-align: right;
  padding-right: 5px;
  padding-top: 2px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 1.5s;
  -moz-transition: 1.5s;
  -o-transition: 1.5s;
  transition: 1.5s;
}

.screen-try.show{
  text-align: right;
  padding-right: 5px;
  padding-top: 2px;
  opacity: 1;
  visibility: visible;
}

.find-letter{
  display: inline-block;
  margin-right: 10px;
  font-size: 1.5em;
  font-weight: bold;
}

.error-letter{
  color : #ff1e1e;
}

.ligne-letter{
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 10px;
  left: 10px;
  -webkit-transition: 1.5s;
  -moz-transition: 1.5s;
  -o-transition: 1.5s;
  transition: 1.5s;
}

.ligne-letter.show{
  opacity: 1;
  visibility: visible;
}
  