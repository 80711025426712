.screen-display{
  height: 80px;
}  
  .message{
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: left;
  }

  .message .Typewriter .Typewriter__wrapper span{
    display: inline-block;
    padding-bottom: 5px;
    padding-left: 10px;
  }