.clavier-container > .letter {
    font-size: 1.5em;
    padding: 8px;;
    flex-basis: 40px;    
    margin: 0.2em;
    display: flex;
    cursor: default;
    justify-content: center;  
    border-radius:  4px; 
    color:#eeeeeeff;
    background-color: rgba(73,71,71,1.0);
    border: 2px solid #1a1a1a;
  }

  .clavier-container > .letter.clicked{
    opacity: 0.2;
    cursor: not-allowed;
  }

  .clavier-container > .letter.visible {
    cursor: not-allowed;
  }
  
  .clavier-container > .letter > .letter-symbol {
    margin: auto;
  }
  