.container{
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.display {
  width: 850px;
  height: 100%;
  font-family: 'Press Start 2P', cursive;
  background-color: #CDCDCD;
  border: 5px solid black;
  border-radius: 3px;
  box-shadow: inset 0 0 10px #050505;
  margin-left: auto;
  margin-right: auto;
  top: 25px;
  padding: 5px;
  text-align: center;
  overflow: hidden;
}

.game-board{
  height: 200px;
  width: 100%;
}